<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row >
                    <b-col
                    md="6"
                    class="d-flex justify-content-start"
                    
                    >
                    <h4 class="text-dark font-weight-bold">Filter</h4>
                    
                    </b-col>
                    <b-col
                    md="6"
                    class="d-flex justify-content-end"
                    >
                    <b-button  
                        type="button"
                        class="ml-1"
                        variant="success"
                        @click="generateReport()"
                            >
                        
                            Print
                        </b-button>
                        <b-button  
                        type="button"
                        variant="primary"
                        class="ml-1"
                        @click="ApplyFilter"
                            >
                            
                            Apply
                        </b-button>
                        <b-button  
                        type="button"
                        class="ml-1"
                        variant="danger"
                        @click="clearFilter"
                            >
                        
                            Clear
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="form-boder-input">
                    <b-col md="3">
                       
                        <div class="demo-vertical-spacing ml-1">
                                <b-form-group
                                label="จากวันที่"
                                label-for="start_date"
                                >
                                <b-form-datepicker
                                    v-model="filterData.start_date"
                                    reset-button

                                    type="date"
                                />
                                </b-form-group>
                            </div>
                    
                    </b-col>
                    <b-col md="3">
                       
                       <div class="demo-vertical-spacing ml-1">
                               <b-form-group
                               label="ถึงวันที่"
                               label-for="end_date"
                               >
                               <b-form-datepicker
                                   v-model="filterData.end_date"
                                   reset-button

                                   type="date"
                               />
                               </b-form-group>
                           </div>
                   
                   </b-col>
                
                </b-row>
            </div>
        </b-card>
        <b-card title="รายงานยอดขาย" >
            <vue-good-table
                :columns="tableColumns"
                :rows="items"
            
                ref="refListTable"
                :select-options="{
                    enabled: false,
                    selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :pagination-options="{
                        enabled: true,
                        perPage:pageLength
                    }"
            >
            <template
                        slot="table-row"
                        slot-scope="props"
                >
            <div v-if="props.column.field === 'total'">
                  {{ getCommaDecimal(props.row.total) }}
                   
                </div>
                </template>
            <div slot="table-actions-bottom" class="p-1"  >
                    <div class="text-right " style="margin-right:47%;">
                       
                          <h4 class="text-black">รวมทั้งหมด <span class="pl-3">{{  getCommaDecimal(pricetotal) }}</span></h4> 
                    </div>
                      
                </div>
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Items per page
                            </span>
                            <b-form-select
                            v-model="pageLength"
                            :options="['10','20','50']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                        
                        </div>
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="Total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
            </vue-good-table>
                    
        </b-card>
        <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        margin="5"
        filename="report.pdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :paginate-elements-by-height="2340"
      
        @hasDownloaded="attemptPrint($event)"
        @beforeDownload="beforeDownload($event)"
        @startPagination="startPagination($event)"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration($event)"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        >
        <section slot="pdf-content" class="pdf-content  text-black p-1">

                <section class="pdf-item p-1"> 
                    <div class="d-flex justify-content-center align-items-center boder-head">
                        <div>รายงานยอดขายระหว่างวันที่</div>
                        <div class="head-date">{{  filterData.start_date?formatDateSlash(filterData.start_date):'-'  }}</div>
                        <div>ถึงวันที่</div>
                        <div class="head-date">{{  filterData.end_date?formatDateSlash(filterData.end_date):'-'  }}</div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center border-b" v-for="item in items">
                        <div style="width:43% ">{{  item.title }}
                        </div>
                        <div style="width:15%; text-align: right;" > {{ getCommaDecimal(item.total) }}</div>
                        <div style="width:42%" > </div>
                        
                    </div>
                    <div class="content-bottom ">
                        <div class="pdf-content-footer d-flex justify-content-end  align-items-center ">
                            <div style="width:46%;text-align: left;"><u>รวมทั้งหมด</u></div>
                            <div style="width:15%;text-align: right;">{{  getCommaDecimal(pricetotal) }}</div>
                            <div style="width:39%;text-align: left; padding-left: 70px;">บาท</div>

                        </div>
                       
                       
                        
                    </div>
                 
                </section>
           
            
            <!--<div class="footer-pdf">
                D
               
            </div>-->
        </section>
        </vue-html2pdf>
    </div>
    
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateSlash,formatDateTh,formatDateToMonthShort,getCommaDecimal,getComma } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
import VueHtml2pdf from 'vue-html2pdf'
import reportStoreModule from '../reportStoreModule'
import useSalesList from './useSalesList'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable,
    vSelect,
    VueHtml2pdf

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            getCommaDecimal,
            getComma,
            formatDateSlash
        }
    },
    setup(props, { emit }){
        const REPORT_STORE_MODULE_NAME = 'app-report';
        if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
        onUnmounted(() => {
            if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
        });
        const {
            filterData,
            ApplyFilter,
            clearFilter,
            tableColumns,
            items,
            refListTable,
            pageLength,
            currentPage,
            Total,
            pricetotal,
            getPrintData,
            itemsPrint,
            priceprinttotal
        } = useSalesList();
        return {
            filterData,
            ApplyFilter,
            clearFilter,
            tableColumns,
            items,
            refListTable,
            pageLength,
            currentPage,
            Total,pricetotal,
            getPrintData,
            itemsPrint,
            priceprinttotal
        }
    },
    methods:{
        renderSuggestion(suggestion){
           
            return suggestion.item.first_name+' '+suggestion.item.last_name
        },
         async generateReport () {
            console.log('generateReport');
           // await this.getPrintData();
           
            
           this.$refs.html2Pdf.generatePdf();
        },
       async onProgress(event){
            console.log('onProgress',event);
           // console.log('event', event);
        },
        async hasStartedGeneration(){
            console.log('hasStartedGeneration');
           
        },
        async startPagination(event){
            console.log('startPagination',event);
            

        },
        async hasGenerated(){
            console.log('hasGenerated');
        },
       
        attemptPrint(blob) {
            console.log('attemptPrint',blob);
       // let url = URL.createObjectURL(blob);
        //window.open(url); //opens the pdf in a new tab
      },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            console.log('beforeDownload',options);
            options.margin = [0.7, 0.1, 0.7, 0.1];
           
          //  console.log('options',options);
        //    console.log('pdfContent',pdfContent);
            
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                var dt = new Date();
                var year  = dt.getFullYear();
                var month = (dt.getMonth() + 1).toString().padStart(2, "0");
                var day   = dt.getDate().toString().padStart(2, "0");
                let date = day+'/'+month+'/'+year
                const totalPages = pdf.internal.getNumberOfPages()
                var text = "Viva Clinic";
               // var xOffset = (pdf.internal.pageSize.getWidth() * 0.88) - (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / 2);
                var xOffset = (pdf.internal.pageSize.getWidth()/2);
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setFontSize(14)
                    pdf.setTextColor('#000000')
                    pdf.text(text, xOffset, (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight()-0.5)),{align: 'center'})
                    pdf.text(date, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight()-0.5)))
                    
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    
                } 
              
            }).output('bloburl').then(r => { window.open(r) });
           
         // this.$refs.html2Pdf.generatePdf()
        }
    },
    
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.pdf-page{
    position: relative;
 
}
.footer-pdf{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}
.boder-head{
    border-top:2px solid #000;
    border-bottom:2px solid #000;
    font-size: 20px;
    padding: 10px;
}
.content-bottom{
    font-size: 20px;
    border-bottom:2px solid #000;
    padding: 10px;
}
.border-b{
    border-bottom:1px solid #ccc;
    width: 75%;
    margin-left:auto ;
    padding: 8px;
    font-size: 16px;
}
.head-date{
    width: 200px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
}
.pdf-content{
    font-family: "Helvetica";
}
.pdf-content-footer{
    width: 80%;
    margin-left:auto ;

}
</style>